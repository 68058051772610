import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import stella from "../images/stella.jpg"

const StyledParagraph = styled.p`
  line-height: 1.6em;
  max-width: 500px;
`

const AboutImage = styled.div`
  background-image: url(${stella});
  width: 100%;
  height: 300px;
  max-width: 300px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0 auto;
  border-radius: 50%;
`

const AboutPage = () => (
  <Layout>
    <SEO title="About" keywords={[`gatsby`, `application`, `react`]} />
    <h1>About Me</h1>
    <StyledParagraph>
      {" "}
      I grew up in Queens, NY and graduated from Brown University. After
      exploring everything from drosophila genetics to nonprofit arts
      fundraising, I began programming and haven't stopped since. I love
      building compelling web & mobile app experiences. Other interests include
      reading, hiking and travel.
    </StyledParagraph>
    <AboutImage />
  </Layout>
)

export default AboutPage
